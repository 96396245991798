import React from 'react'
import ServiceSidebarg from './ServiceSidebarg'
import details1g from '../../assets/images/services/services-details1g.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentg = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1g} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Project Management </span>
                            <h3>About this Service</h3>
                            <p>Every company faces the challenge to create transparency and a single point of truth for the complete project lifecycle. Our PM Solution provides long-term feasibility of projects as well as alignment with the enterprise goals. Our fully integrated solution supports your organization - from the department to the multinational group.</p>
                            <p>Our Project management service provides company-wide management and also covers the entire project life cycle, is fully integrated with the ERP system, and thus gives you an overview of financial and personnel resources. A single point of truth for your PMO that spans the entire project portfolio and enables well-founded strategic decisions. </p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Improved Project Management</li>
                                            <li>Makes Collaboration Easy</li>
                                            <li>Improves Project Planning</li>
                                            <li>Better Client Communication</li>
                                            <li>Maximum Resource Utilization</li>
                                            <li>Risk Management</li>
                                            <li>Quality Control</li>
                                            <li>Budget Management</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>We will help you Manage projects company-wide from a centralized repository and keep the portfolio on track-from forecasting and planning to account and closure</p>
                            <p>Our PM Service will help you to get optimized resources and new products and services to market faster with centralized processes and information with end-to-end project coverage to automate the planning, execution, and monitoring of projects throughout the entire project lifecycle.</p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Accelerated SAP (ASAP)</span></li>
                                <li><span>Solution Manager</span></li>
                                <li><span>ERP PS Module</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarg />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentg